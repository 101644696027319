import React from 'react'
import { graphql } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import Layout from '../components/Layout';
import Link from '../components/Link';
import SEO from '../components/Seo';

export default function Page({ data }) {
    const content = data?.allBuilderModels?.onePage?.content;
  return (
    <Layout>
        <SEO />
        <BuilderComponent renderLink={Link} model="page" content={content} />
    </Layout>
  )
}

export const query = graphql`
    query BuilderPage($id: String) {
        allBuilderModels {
            onePage(query: {id: $id}) {
                id
                content
            }
        }
    }
`;