import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from "react-helmet";

export default function Container({ children, style }) {
    const data = useStaticQuery(graphql`
        query MetadataSiteSettings {
            allBuilderModels {
                oneSiteSettings {
                    data {
                        siteName,
                        siteDescription,
                        siteUrl,
                        siteIcon,
                        socialMediaImage,
                        containerWidth
                    }
                }
            }
        }      
    `)

    const settings = data?.allBuilderModels?.oneSiteSettings?.data;

    const sizeImage = function(size) {
        var imageUrl = settings.siteIcon;
        return imageUrl + '?width=' + size + '&height=' + size;
    }

  return (
    <Helmet>
        <title>{ settings.siteName }</title>
        <meta name="description" content={ settings.siteDescription } />

        <link rel='icon' type='image/png' href={ sizeImage(32) } />
        <link rel='icon' sizes='192x192' href={ sizeImage(192) } />
        <link rel='apple-touch-icon' href={ sizeImage(152) } />
        <meta name='msapplication-square310x310logo' content={ sizeImage(310) } />

        <meta property="og:title" content={ settings.siteName } />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ settings.siteUrl } />
        <meta property="og:image" content={ settings.socialMediaImage } />

        <meta name="twitter:title" content={ settings.siteName } />
        <meta name="twitter:description" content={ settings.siteDescription } />
        <meta name="twitter:image" content={ settings.socialMediaImage } />
        <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}